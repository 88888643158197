@use "sass:math";

@import '../../scss/config';

.list {
  display: none;
  margin: 0;
  padding: 0;

  &.on {
    display: block;
  }
}

.item {
  border-bottom: 1px solid $color-gray-light;
  list-style: none;
  margin: 0;
  padding: 24px 0;

  &:last-child {
    border-bottom: 0;
  }
}

.title {
  font-family: $font-family-sans-serif;
  font-size: $font-size-sm;
  line-height: 1;
  margin-bottom: 12px;
}

.options {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -20px;
}

.option {
  padding: 0 12px 20px 0;
  width: math.div(100, 3) * 1%;
}

.img {
  img {
    border: 1px solid $color-gray-light;
    border-radius: 50%;
    height: 24px;
    width: 24px;
  }

  &.on {
    position: relative;

    &::after {
      border: solid 1px $color-gray-light;
      border-radius: 50%;
      content: '';
      height: 32px;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 32px;
    }
  }
}

@media (max-width: $break-xsmall) {
  .option {
    width: 50%;
  }
}
