@import '../../scss/config';

.root {
  align-items: center;
  display: flex;
}

.centered {
  justify-content: center;
}

.input {
  appearance: none;
  border: 2px solid $color-charcoal;
  border-radius: 14px;
  cursor: pointer;
  flex-shrink: 0;
  height: 28px;
  margin: 0 6px 0 0;
  position: relative;
  width: 28px;

  &::before {
    background-color: $color-ov-blue;
    border-radius: 9px;
    content: '';
    display: block;
    height: 18px;
    left: 50%;
    margin: -9px 0 0 -9px;
    position: absolute;
    top: 50%;
    transform: scale(0);
    transition: transform .2s $ease-default;
    width: 18px;
  }

  &:checked::before {
    transform: scale(1);
  }
}

.label {
  align-items: center;
  cursor: pointer;
  display: flex;
  font-family: $font-family-sans-serif;
  font-size: $font-size-sm;
}
