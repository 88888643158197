@import '../../scss/config';
@import '../../scss/mixins';

.root {
  background: $color-white;
  display: none;
  height: 46px;
  position: sticky;
  top: $nav-height - 1px;
  width: 100%;
  z-index: $z-filters;
}

.inner {
  @include match-grid();

  display: flex;
  height: 100%;
  justify-content: space-between;
}

.item {
  list-style: none;
  margin: 0;
  padding: 0;

  &.mobile {
    display: none;
  }

  &.bordered {
    margin-left: 6px;
    padding-left: 24px;
    position: relative;

    &::before {
      background: $color-gray-light;
      content: '';
      height: 22px;
      left: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 1px;
    }
  }
}

.list {
  align-items: center;
  display: flex;
  height: 100%;
  margin: 0;
  padding: 0;

  &:last-child {
    .item {
      margin-left: 16px;
    }
  }

  &:first-child {
    .item {
      margin-left: 0;
      margin-right: 16px;
    }
  }
}

.icon {
  transition: transform $ease-in-out $ease-timing-medium;

  &.flip {
    display: block;
    transform: rotate(180deg) translateY(50%) !important;
  }
}

.dropdown {
  background: $color-white;
  border-top: solid 1px $color-gray-light;
  display: none;
  opacity: 0;
  transition:
    opacity $ease-in-out $ease-timing-medium,
    visibility $ease-in-out $ease-timing-medium;
  visibility: hidden;
  width: 100%;

  &.on {
    opacity: 1;
    visibility: visible;
  }
}

.dropdownInner {
  @include match-grid();
}

@media (max-width: $break-two-column) {
  .root {
    display: block;
  }

  .dropdown {
    display: block;
  }

  .item {
    &.desktop {
      display: none;
    }

    &.mobile {
      display: block;
    }
  }
}
