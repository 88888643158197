@import '../../scss/config';

.root {
  background-color: #277db0;
  border-bottom: 1px solid $color-gray-xlight;
  display: flex;
  flex-direction: column;
  height: 725px;
  justify-content: center;
  text-align: center;
  width: 100%;
}

.title,
.subtitle {
  color: $color-white;
  font-weight: 500;
  position: relative;
  text-align: center;
  z-index: 1;
}

.title {
  font-family: $font-family-sans-serif;
  font-size: 72px;
  line-height: normal;
  margin: 0 auto 12px;
}

.subtitle {
  font-family: $font-family-serif;
  font-size: $font-size-lg;
  font-style: italic;
  margin-bottom: 48px;
  margin-top: 0;
}

.copy {
  color: $color-white;
  font-family: $font-family-sans-serif;
  font-size: $font-size-sm;
  margin: 0 auto 24px;
  max-width: 702px;

  + .copy {
    margin: 0 auto 60px;
  }
}

.image {
  display: block;
  margin: 0 auto 24px;
  width: 340px;
}

.imageMobile {
  display: none;
  margin: 0 auto 42px;
  width: 174px;
}

.jumper {
  align-items: center;
  background: none;
  border: 0;
  color: $color-white;
  display: flex;
  flex-direction: column;
  font-family: $font-family-sans-serif;
  justify-content: center;
  margin: 0 auto;
  padding: 0;
  transition: opacity $ease-in-out $ease-timing-medium;
  width: 56px;

  path {
    fill: currentColor;
  }

  &:hover {
    color: $color-white;
    opacity: .8;

    path {
      fill: currentColor;
    }
  }

  &:active {
    position: relative;
    top: 1px;
  }
}

@media (max-width: $break-two-column) {
  .root {
    height: auto;
    padding: 48px 24px 0;
  }

  .image,
  .jumper {
    display: none;
  }

  .imageMobile {
    display: block;
  }

  .title {
    font-size: $font-size-xl;
    margin-bottom: 6px;
  }

  .subtitle {
    font-size: $font-size-smd;
    margin-bottom: 36px;
  }

  .copy {
    font-size: $font-size-xsm;
    margin-bottom: 12px;

    + .copy {
      margin-bottom: 6px;
    }
  }
}
